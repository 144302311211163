var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{staticClass:"h20",attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("SELECT "+_vm._s(_vm.pdfOnly ? "PDF FILE" : "IMAGE"))])],1),_c('v-card-text',[(_vm.content)?_c('v-row',[_vm._l((_vm.content),function(item,index){return _c('v-col',{key:index,staticClass:"d-flex child-flex",attrs:{"cols":"2"}},[_c('v-img',{staticClass:"grey lighten-2 pointer",attrs:{"src":(_vm.baseUrl + "/thumbnails/" + (item.thumbnail)),"lazy-src":(_vm.baseUrl + "/thumbnails/" + (item.thumbnail)),"aspect-ratio":"1"},on:{"click":function () {
                    _vm.$emit('selectedImage', item);
                    _vm.$emit('close');
                  },"mouseover":function($event){{
                    _vm.pipHover = true;
                    _vm.selectedIndex = index;
                  }},"mouseleave":function($event){{
                    _vm.pipHover = false;
                    _vm.selectedIndex = null;
                  }}}})],1)}),_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}})],2):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }