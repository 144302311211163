<template>
  <div>
    <v-row v-if="images">
      <v-col
        v-for="(item, index) in images"
        :key="index"
        :class="{ 'd-flex child-flex': !custom }"
        :cols="custom ? 6 : 2"
      >
        <v-img
          :src="`${baseUrl}/thumbnails/${item.thumbnail}`"
          :lazy-src="`${baseUrl}/thumbnails/${item.thumbnail}`"
          aspect-ratio="1"
          class="grey lighten-2"
          @mouseover="
            {
              pipHover = true;
              selectedIndex = index;
            }
          "
          @mouseleave="
            {
              pipHover = false;
              selectedIndex = null;
            }
          "
        >
          <div v-if="pipHover && selectedIndex === index" class="options">
            <div>
              <div class="edit my-2">
                <v-btn
                  @click="
                    () => {
                      show = true;
                      indexOfItem = index;
                    }
                  "
                  color="accent"
                  elevation="1"
                  fab
                  small
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <div v-if="deleteMode" class="delete my-2">
                <v-btn
                  @click="deleteImage(index)"
                  color="red"
                  elevation="1"
                  fab
                  small
                >
                  <v-icon color="white">mdi-delete-forever</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-img>
      </v-col>

      <v-col
        v-if="images.length < max"
        :class="{ 'd-flex child-flex': !custom }"
        :cols="custom ? 6 : 2"
      >
        <div class="add">
          <div
            @click="
              () => {
                show = true;
              }
            "
          >
            <v-icon large>mdi-plus</v-icon>
          </div>
        </div>
      </v-col>
    </v-row>
    <ImagePickerDialog
      :show="show"
      @close="
        () => {
          show = false;
        }
      "
      @selectedImage="selectedImage"
    />
  </div>
</template>

<script>
import config from "@/config";
import ImagePickerDialog from "./ImagePickerDialog";

export default {
  props: {
    images: {
      type: Array,
      default: () => {
        return [];
      }
    },

    custom: {
      type: Boolean,
      default: false
    },

    deleteMode: {
      type: Boolean,
      default: true
    },

    max: {
      type: Number,
      default: 20
    }
  },

  components: {
    ImagePickerDialog
  },

  data() {
    return {
      pipHover: false,
      selectedIndex: null,
      indexOfItem: null,
      show: false
    };
  },

  watch: {},

  computed: {
    baseUrl() {
      return config.mediaBaseUrl;
    }
  },

  methods: {
    selectedImage(value) {
      const { images, indexOfItem } = this;

      if (images[indexOfItem]) {
        this.images[indexOfItem] = value;
        this.$emit("getImages", this.images);
      } else {
        this.images.push(value);
        this.$emit("getImages", this.images);
      }

      this.indexOfItem = null;
    },

    deleteImage(index) {
      const { images } = this;

      if (index > -1) {
        images.splice(index, 1);
      }
    }
  },

  mounted() {}
};
</script>

<style lang="scss" scoped>
.v-image {
  background-color: #ffffff !important;
  border: solid red !important;
}

.options {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  div {
    position: relative;
  }
}

.add {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    background: rgb(204, 204, 204);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
