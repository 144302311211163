<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar class="h20" flat dark color="primary">
            <v-btn icon dark @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title
              >SELECT {{ pdfOnly ? "PDF FILE" : "IMAGE" }}</v-toolbar-title
            >
          </v-toolbar>

          <!-- file picker -->
          <v-card-text>
            <v-row v-if="content">
              <v-col
                v-for="(item, index) in content"
                :key="index"
                class="d-flex child-flex"
                cols="2"
              >
                <v-img
                  @click="
                    () => {
                      $emit('selectedImage', item);
                      $emit('close');
                    }
                  "
                  :src="`${baseUrl}/thumbnails/${item.thumbnail}`"
                  :lazy-src="`${baseUrl}/thumbnails/${item.thumbnail}`"
                  aspect-ratio="1"
                  class="grey lighten-2 pointer"
                  @mouseover="
                    {
                      pipHover = true;
                      selectedIndex = index;
                    }
                  "
                  @mouseleave="
                    {
                      pipHover = false;
                      selectedIndex = null;
                    }
                  "
                />
              </v-col>

              <infinite-loading @infinite="infiniteHandler" />
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import config from "@/config";
import InfiniteLoading from "vue-infinite-loading";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },

    pdfOnly: {
      type: Boolean,
      default: false
    }
  },

  components: {
    InfiniteLoading
  },

  data() {
    return {
      content: [],
      quantity: 1,
      loading: false,
      totalPages: 1,
      params: {
        page: 1,
        perPage: 30
      }
    };
  },

  watch: {
    show(e) {
      if (e) {
        this.content = {};
        this.params.page = 1;
        this.loadData();
      }
    }
  },

  computed: {
    baseUrl() {
      return config.mediaBaseUrl;
    }
  },

  methods: {
    ...mapActions({
      getFiles: "file/getFiles"
    }),

    async loadData() {
      const params = this.params;

      if (this.pdfOnly) {
        params.mimetype = "pdf";
      }

      const data = await this.getFiles({ params });
      if (params.page === 1) {
        this.content = data.data;
      }
      this.totalPages = data.totalPages;

      return data.data;
    },

    async infiniteHandler($state) {
      if (this.totalPages > this.params.page) {
        this.params.page = this.params.page + 1;
        const data = await this.loadData();
        this.content.push(...data);

        $state.loaded();
      } else {
        $state.complete();
      }
    }
  },

  mounted() {}
};
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
