var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.images)?_c('v-row',[_vm._l((_vm.images),function(item,index){return _c('v-col',{key:index,class:{ 'd-flex child-flex': !_vm.custom },attrs:{"cols":_vm.custom ? 6 : 2}},[_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":(_vm.baseUrl + "/thumbnails/" + (item.thumbnail)),"lazy-src":(_vm.baseUrl + "/thumbnails/" + (item.thumbnail)),"aspect-ratio":"1"},on:{"mouseover":function($event){{
            _vm.pipHover = true;
            _vm.selectedIndex = index;
          }},"mouseleave":function($event){{
            _vm.pipHover = false;
            _vm.selectedIndex = null;
          }}}},[(_vm.pipHover && _vm.selectedIndex === index)?_c('div',{staticClass:"options"},[_c('div',[_c('div',{staticClass:"edit my-2"},[_c('v-btn',{attrs:{"color":"accent","elevation":"1","fab":"","small":""},on:{"click":function () {
                    _vm.show = true;
                    _vm.indexOfItem = index;
                  }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),(_vm.deleteMode)?_c('div',{staticClass:"delete my-2"},[_c('v-btn',{attrs:{"color":"red","elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.deleteImage(index)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-delete-forever")])],1)],1):_vm._e()])]):_vm._e()])],1)}),(_vm.images.length < _vm.max)?_c('v-col',{class:{ 'd-flex child-flex': !_vm.custom },attrs:{"cols":_vm.custom ? 6 : 2}},[_c('div',{staticClass:"add"},[_c('div',{on:{"click":function () {
              _vm.show = true;
            }}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-plus")])],1)])]):_vm._e()],2):_vm._e(),_c('ImagePickerDialog',{attrs:{"show":_vm.show},on:{"close":function () {
        _vm.show = false;
      },"selectedImage":_vm.selectedImage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }